import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import { convertDate, currency, getStaff } from '../Utils';
import { uniqueJobStates, entryContainsString, removeDuplicates, findUserInEntry } from '../CaseUtils';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class CasesTable extends Component {

    constructor(props) {
        super(props);
        this.userChange = this.userChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.state = {
            loading: true,
            users: [],
            user: '',
            pagination: {
                rpp: this.props.rpp ? parseInt(this.props.rpp) : 12,
                current_page: 1,
                total_results: 0,
                nb_pages: 0
            },
            job_states: [],
            job_state: '',
            all_chunks_loaded: false,
            current_chunk: 0,
            cases_data: [], // to hold all possible cases
            cases: [], // filtered cases only
            filter: ''
        } 
    }

    handleFilterChange = (e) => {
        this.setState({ filter: e.target.value });
    }

    userChange(event) {
        let {name: fieldName, value} = event.target;
        this.filterResults();
    }

    componentDidMount() {
        getStaff(false).then(response => {
            let users = response
            if(users===null) {
                users = []
            }
            this.setState({ job_state: 'Live', user: localStorage.getItem('bo_email'), users: users }, ()=>{ 
                this.load();
            })
        })

    }

    onPageChange(v) {
        let pagination = this.state.pagination;
        pagination.current_page = v;
        this.setState({pagination: pagination},  () => {
            this.filterResults();
        })
    }

    load() {


        if(this.state.all_chunks_loaded) {
            return false;
        }

        let start = this.state.current_chunk * 30
        let end = start + 30

        let searchForUser = this.state.user;
        if (searchForUser==='all') searchForUser = "";
        if (searchForUser==='current') {
            this.setState({user: localStorage.getItem('bo_email')})
            searchForUser = localStorage.getItem('bo_email');
        }

        if(start===0) {
            console.log('filtering for ' + searchForUser);
            this.setState({ loading: true });
        }

        // if(value===null) value = localStorage.getItem('bo_email');
        axios.post(config().base_url + 'SearchCasesBySearchTypeAndUserPermissionsJSONV2', {
                "UserInfo": {
                    "Username": localStorage.getItem('bo_email'),
                    "Password": localStorage.getItem('bo_password')
                },
                "pRequest": {
                    Status: "",
                    SearchType: "Case Assignment",
                    SearchText: searchForUser,
                    startDate: "2007-01-01",
                    endDate: "2100-01-01",
                    recordStartNo: start,
                    recordEndNo: end,
                    OrderType: [
                        {
                            "OrderOn": "code",
                            "OrderFlow": "desc"
                        }
                    ]
                }    
        })
        .then(response => {

            this.setState({
                loading: false,
                current_chunk: this.state.current_chunk + 1,
                cases_data: this.state.cases_data.concat(response.data.d.Records)
                // cases: this.filterResults(response.data.d.Records, start, end, filter),
                // cache: { key: caching_key, result: response.data.d.Records }
            }, () => {
                this.filterResults();                
                if(response.data.d.Records.length>0 && this.state.current_chunk<100) {
                    this.load();
                } else {
                    console.log('all data loaded (' + this.state.cases_data.length + ')');
                    this.setState({all_chunks_loaded: true });
                }
            })
            
        })
        .catch(error => {
            this.setState({loading: false, all_chunks_loaded: true});
            console.log(error);
        });
    }

    renderRow(entry) {
        let user = this.state.user;
        let filter = this.state.filter.toUpperCase();
        let condition_filter = false;
        // main conditions
        let condition_main = ((user && entry.OwnerName==user) || (!user && entry.OwnerName==config().personName ));
        // condition for filtering
        if(condition_main && filter) {
            const values_to_compare = [entry.CustomerReference, convertDate(entry.DateCreated), entry.CompanyName, entry.Firstname, entry.Surname, entry.CustomerAddress.Address1, entry.CustomerAddress.PostCode, entry.CustomerStatus];
            values_to_compare.forEach(element => {
                if (element && element.toUpperCase().indexOf(filter) != -1) {
                    condition_filter = true;
                }
            });
        } else {
            condition_filter = true;
        }
        // console.log(condition_main && condition_filter);
        return condition_main && condition_filter;
    }

    filterResults() {

        let filter_user = this.state.user;
        let filter_text = this.state.filter;
        let filter_status = this.state.job_state;

        let arr = []; // local result array 
        let cp = this.state.pagination.current_page;
        let rpp = this.state.pagination.rpp;

        // console.log(this.state.pagination);

        for(let i=0; i<this.state.cases_data.length; i++) {

            let filter1 = filter_status ? this.state.cases_data[i].JobStatus===filter_status : true;
            // let filter2 = filter_user ? findUserInEntry(this.state.cases_data[i], filter_user) : true;
            let filter3 = filter_text ? entryContainsString(this.state.cases_data[i], filter_text) : true;

            if(filter1 /* && filter2 */ && filter3) {
                arr.push(this.state.cases_data[i])
            }
        }
        
        // console.log((cp-1)*rpp, cp*rpp)
        let pagination = this.state.pagination
        pagination.total_results = arr.length

        // reset page to 1 if current page > total pages available
        let total_page_available = Math.ceil(arr.length / pagination.rpp)
        if(pagination.current_page > total_page_available) {
            pagination.current_page = 1;
        }

        this.setState({
            pagination: pagination,
            job_states: uniqueJobStates(this.state.cases_data),
            cases: arr.slice((cp-1)*rpp, cp*rpp)
        })
    }

    renderRow(entry) {
        let user = this.state.user;
        let filter = this.state.filter.toUpperCase();
        let condition_filter = false;
        // main conditions
        let condition_main = true;
        // condition for filtering
        if(condition_main && filter) {
            const values_to_compare = [entry.JobReference, convertDate(entry.DateCreated), entry.CustomerNames, entry.Term + "", entry.JobStatus];
            values_to_compare.forEach(element => {
                if (element && element.toUpperCase().indexOf(filter) != -1) {
                    condition_filter = true;
                }
            });
        } else {
            condition_filter = true;
        }
        return condition_main && condition_filter;
    }

    render() {
        const { cases, loading, pagination } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6"><h4 className="font-weight-bold">Cases</h4></div>
                    <div className='col-md-2'>
                        <input className="filters" type="text" placeholder='Search' value={this.state.filter} onChange={(e) => this.setState({filter: e.target.value}, function(){ this.filterResults() })}  />
                    </div>
                    <div className="col-md-2">
                        <select style={{ maxWidth: '100%' }} className="filters" value={this.state.job_state} onChange={(e) => this.setState({job_state: e.target.value}, function(){ this.filterResults() })}>
                            {this.state.job_states.map((v) => <option key={v} value={v}>{v}</option>)}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select style={{ maxWidth: '100%' }} className="filters" value={this.state.user} onChange={(e) => this.setState({user: e.target.value, all_chunks_loaded: false, cases_data: [], current_chunk: 0}, function(){ this.load() })}>
                            <option value="all"></option>
                            <option value="current">Current User</option>
                            {this.state.users.map((v) => <option key={v.value} value={v.value}>{v.label}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th width="10%">Case Ref.</th>
                                        <th width="10%">Date</th>
                                        <th width="30%">Customer</th>
                                        <th width="5%" className='text-right'>Term</th>
                                        <th width="15%" className='text-right'>Total Advance</th>
                                        <th width="10%">Status</th>
                                        <th width="10%"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    cases.length ?
                                    cases.map((entry, key) => {
                                        return this.renderRow(entry) ? <tr key={key}>
                                            <td>{ entry.JobReference }</td>
                                            <td>{ convertDate(entry.DateCreated) }</td>
                                            <td>{ entry.CustomerNames }</td>
                                            <td className='text-right'>{ entry.Term }</td>
                                            <td className='text-right'>{ currency(entry.LoanAmount) }</td>
                                            <td>{ entry.JobStatus }</td>
                                            <td className='text-center'>
                                                <Link to={ '/case/' + entry.JobReference } className="btn-more"></Link>
                                            </td>
                                        </tr> : null
                                    }) : 
                                    null
                                }
                                </tbody>
                            </table>
                            <Pagination className="pagination-bar" currentPage={pagination.current_page} totalCount={pagination.total_results} pageSize={pagination.rpp} onPageChange = {this.onPageChange} />
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default CasesTable;