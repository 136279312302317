import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble } from './../Utils'
import alertify from 'alertifyjs';

const endpoint = "CreateNewProspectV2";

export const executeCreateNewProspectV2Lead = (att) => {
    
    let payload = {
        "userInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "customer": {
            "Firstname": att.firstName,
            "Surname": att.surname,
            "CompanyName": att.CompanyName,
            "Telephone": "",
            "Email": "",
            "DateOfBirth": convertDateForApi(""),
            "CustomerStatus": "Lead",
            "B2BCustomer": att.B2B,
            "DateCreated": att.DateCreated,
            "Source": att.Source,
            "SourceAffiliate": att.SourceAffiliate,
            "AltSalutation": att.AltSalutation,
            "OwnerName": att.OwnerName,
            "Source": att.Source,
            "SourceAffiliate": att.SourceAffiliate,
            "SourceType": att.SourceType,
            "InvoiceTermName":"",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": "",
                "Address2": "",
                "Address3": "",
                "Address4": "",
                "PostCode": ""
            },
            "CustomFields": [
                {
                  "index": "23",
                  "CustomFieldValue": att.CustomFieldValue23
                },
                {
                  "index": "24",
                  "CustomFieldValue": att.CustomFieldValue24
                },
                {
                    "index": "25",
                    "CustomFieldValue": att.CaseAssignmentBranch
                  }
              ]
        },
        "ClientPassword": ""
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

export default {
    executeCreateNewProspectV2Lead
};
