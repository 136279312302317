import React, { Component } from 'react';
import { config } from '../Configurator'
import { creditSearch, creditSafeAddCompanyToPortfolio } from '../Utils';

class addToPortfolio extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customer_reference: null,
            disable_portfolio_button: false,
            forms: config().forms
        }
    }

    componentDidMount() {
        this.setState({ 'customer_reference': this.props.customer_reference});
    }

    creditSearchButtonClick() {
        let that = this;
        that.setState({disable_portfolio_button: true});
        creditSearch(this.state.customer_reference, 'records').then((data) => {

            that.setState({disable_portfolio_button: false});
            if(data && data[0] && data[0]['id']) {
                alert("Adding " + data[0]['id'] + " to portfolio");
                that.setState({disable_portfolio_button: true});

                creditSafeAddCompanyToPortfolio(this.state.customer_reference, data[0]['id']).then((data) => {
                    that.setState({disable_portfolio_button: false});
                    console.log(data)
                }, () => {
                    that.setState({disable_portfolio_button: false});
                });
    
            } else {
                alert("ID not found");
            }

        }, () => {
            that.setState({disable_portfolio_button: false});
        });
    }

    render() {
        const { customer_reference, disable_portfolio_button } = this.state;
        return (
            <div>
                <button className="btn btn-outline-dark btn-block btn-sm my-2"  disabled={disable_portfolio_button} onClick={this.creditSearchButtonClick.bind(this)}>
                    <span><img src="/images/loading.gif" height={16} /></span> Add To Portfolio
                </button>
            </div>
        )
    }

}

export default addToPortfolio;