import React, { Component } from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { currency, convertToDouble } from '../Utils';
import { config } from '../Configurator'
import Documents from '../components/DocumentsCause'
// import CreditSafe from '../components/CreditSafe'
// import CreditSafeDirectorSearch from '../components/CreditSafeDirectorSearch'
// import AddToPortfolio from '../components/AddToPortfolio'
// import CreditSafeConsumerSearch from '../components/CreditSafeConsumerSearch'

class CaseView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entry: {},
            crypto: require('crypto'),
            case_url: '',
            customer_reference: null,
            applicant_id: this.props.id,
            name: null,
            forms: config().forms
        }
    }

    componentDidMount() {
        
        // get case details
        axios.post(config().base_url + 'GetCase', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "CaseReference": this.props.id
        })
        .then(response => {
            
            // console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            // console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);

            // this.child.loadData(response.data.d);

            this.setState({
                entry: response.data.d.CaseApplicant, 
                case_oldref: response.data.d.CaseApplicant.OldCaseRef,
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                customer_reference: response.data.d.CaseApplicant.CustomerReference,
                name: (response.data.d.CaseApplicant.MainApplicant.Firstname ?? "") + ' ' + (response.data.d.CaseApplicant.MainApplicant.Surname ?? "")}, () => {
                    this.resolveCaseURL()
                })
            })
            .catch(error => {
                console.log(error);
            });


    }
    resolveCaseURL() {
        // entry.Provider
        let case_url = "";
        let key = "106CD07195E140949022A156B5D089C3";

        if(this.state.entry.Provider==='Reward-Finance-Group-Ltd') {
            // case_url = 'https://webforms.uk.pt-x.com/forms/reward%20finance%20group/rfg?&DdPlanReference=' + this.state.entry.OldCaseRef
            // https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rfg?/&ShowApplyingAsCompanyCheck=visible&ShowCompanyName=visible
            // https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rcl?/&ShowApplyingAsCompanyCheck=visible&ShowCompanyName=visible
            // case_url = 'https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rfg?edata=' + this.encrypt(key, '&DdPlanReference=' + this.state.entry.OldCaseRef)

            case_url = 'https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rfg?edata=' + this.encrypt(key, '&ShowApplyingAsCompanyCheck=visible&ShowCompanyName=visible&DdPlanReference=' + this.state.entry.OldCaseRef)
        }

        if(this.state.entry.Provider==='Reward-Capital-Ltd') {
            // case_url = 'https://webforms.uk.pt-x.com/forms/reward%20finance%20group/rcl?&DdPlanReference=' + this.state.entry.ApplicantID            
            // case_url = 'https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rcl?edata=' + this.encrypt(key, '&DdPlanReference=' + this.state.entry.OldCaseRef)
            
            case_url = 'https://webforms.uk.pt-x.com/forms/rewardfinancegroup/rcl?edata=' + this.encrypt(key, '&ShowApplyingAsCompanyCheck=visible&ShowCompanyName=visible&DdPlanReference=' + this.state.entry.OldCaseRef)
        }

        this.setState({
            case_url: case_url
        })
    }

    encrypt(key, plaintext) {
        var sha256 = this.state.crypto.createHash('sha256');
        sha256.update(key);
      
        // Initialization Vector
        var iv = this.state.crypto.randomBytes(16);
        var cipher = this.state.crypto.createCipheriv('aes-256-cbc', sha256.digest(), iv);
      
        var cipherText = cipher.update(plaintext);
        var encrypted = Buffer.concat([iv, cipherText,                   
            cipher.final()]).toString('base64');                                                                         
      
        return encrypted;
    }
    
    render() {
        const { entry, name, case_url } = this.state;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h2>Summary</h2>
                            </div>
                            {/* <div className="col-3 text-right">
                                <Link className="float-right btn btn-light btn-sm" to='/cases'>&larr; Back</Link>
                            </div> */}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">Customer Ref:<br /><strong>{ entry.CustomerReference }</strong></div>
                                        <div className="mb-3">Customer Name:<br /><strong>{ name }</strong></div>
                                        <div className="mb-3">Customer Telephone:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.Telephone :"" }</strong></div>
                                        <div className="mb-3">Customer Mobile:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.Mobile :"" }</strong></div>
                                        <div className="mb-3">Customer Email:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.EmailAddress :"" }</strong></div>
                                        {/* <div className="mb-3">Customer Address:<br /><strong>{ entry.MainApplicant.CurrentAddress ? entry.MainApplicant.CurrentAddress ?? entry.MainApplicant.CurrentAddress.Address1 :"" }</strong></div> */}
                                        {/* <div className="mb-3">Customer Postcode:<br /><strong>{ entry.MainApplicant.CurrentAddress ? entry.MainApplicant.CurrentAddress ?? entry.MainApplicant.CurrentAddress.PostCode :"" }</strong></div> */}
                                        {/* <div className="mb-3">Customer Owner:<br /><strong>{ entry.CustomerOwner }</strong></div> */}
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="mb-3">Case Ref:<br /><strong>{ entry.ApplicantID }</strong></div>
                                        {/* <div className="mb-3">Owner:<br /><strong>{ entry.CaseOwner }</strong></div> */}
                                        <div className="mb-3">BDM:<br /><strong>{ entry.CaseUnderWriter }</strong></div>
                                        <div className="mb-3">RM:<br /><strong>{ entry.CaseProcessor }</strong></div>
                                        <div className="mb-3">Exec:<br /><strong>{ entry.CaseAdditionalUser1 }</strong></div>
                                        <div className="mb-3">Case Matter Type:<br /><strong>{ entry.MatterType }</strong></div>
                                        {/* <div className="mb-3">Term:<br /><strong>12 Months</strong></div> */}
                                        <div className="mb-3">Source:<br /><strong>{ entry.SourceName }</strong></div>
                                        <div className="mb-3">Broker Name:<br /><strong>{ entry.SourceAffiliateName }</strong></div>
                                        <div className="mb-3">Provider:<br /><strong>{ entry.Provider }</strong></div>
                                        <div className="mb-3">Credit Type:<br /><strong>{ entry.CreditType }</strong></div>
                                    </div>
                                    <div className="col-md-1"> </div>
                                    <div className="col-md-3">
                                    <div className="mb-3">Case Status:<br /><strong>{ entry.CaseStatus }</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <br />
                                <div className="row">
                                    <div className="col-md-4">Facility Limit<br /><h2>{ currency( convertToDouble(entry.NetClaim) ) }</h2></div>
                                    <div className="col-md-4">Current Balance<br /><h2>{ currency( convertToDouble(entry.TotalBalance) ) }</h2></div>
                                    <div className="col-md-4">Available Balance<br /><h2>{ currency( convertToDouble(entry.NetClaim) - convertToDouble(entry.TotalBalance) ) }</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h2 className="mb-3">Documents</h2>
                        <Documents customer_reference={this.state.customer_reference} applicant_id={this.state.applicant_id}></Documents>

                        <br />

                        <h2 className="mb-3">Direct Debit Mandate</h2>
                        <div className='card'>
                            <div className='card-body'>
                                <a href={case_url} className='btn btn-outline-dark btn-block btn-sm' target="_blank" rel="noopener noreferrer">Direct Debit Mandate</a>
                            </div>
                        </div>


                        {/* <h2 className="my-3">CreditSafe</h2>

                        <div className='card'>
                            <div className='card-body'>
                                <CreditSafe customer_reference={customer_reference}></CreditSafe>
                                <CreditSafeDirectorSearch customer_reference={customer_reference}></CreditSafeDirectorSearch>
                                <AddToPortfolio customer_reference={customer_reference}></AddToPortfolio>
                                <CreditSafeConsumerSearch ref={instance => { this.child = instance; }} ></CreditSafeConsumerSearch>

                            </div>
                        </div> */}

                    </div>
                </div>
                </div>
        )
    }

}

export default CaseView;