import axios from 'axios';
import { config } from '../Configurator'
// import { multiplyFields, convertDateForApi, convertToDouble } from './../Utils'
import alertify from 'alertifyjs';

const endpoint = "UpdateCustomer";

export const executeUpdateCustomerLeadWithCompName = (att, applicant_id, customer_reference) => {
    
    let payload = 
        {
            "Customer": {
              "CustomerReference": applicant_id,
              "Title1": "",
              "Firstname": att.firstName,
              "Surname": att.surname,
              "AltSalutation": att.AltSalutation,
              "CustomerStatus": "Lead",
              "Source": att.Source,
              "SourceAffiliate": att.SourceAffiliate,
              "SourceType": att.SourceType,
              "B2BCustomer": att.B2B,
              "CompanyName": att.CompanyName,
              "CompanyAddress": {},
              "AlternateSalutation": att.AltSalutation,
              "OwnerName": att.OwnerName,
              "CustomFields": [
                  {
                    "index": "23",
                    "CustomFieldLabel": "",
                    "CustomFieldValue": att.CustomFieldValue23
                  },
                  {
                    "index": "24",
                    "CustomFieldLabel": "",
                    "CustomFieldValue": att.CustomFieldValue24
                  },
                  {
                    "index": "25",
                    "CustomFieldLabel": "",
                    "CustomFieldValue": att.CaseAssignmentBranch
                  }
                ],
              "PassportNumber": "",
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
          }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + applicant_id;
        } else {
          alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }
    }).catch(function (error){
      alertify.confirm('Error', error.message, null, null);
    });

}

export default {
    executeUpdateCustomerLeadWithCompName
};
