import axios from 'axios';
import { config } from '../Configurator'
// import { multiplyFields, convertDateForApi, convertToDouble } from '../Utils'
import alertify from 'alertifyjs';

const endpoint = "UpdateCustomer";

export const executeUpdateCustomerOnlyStatus = (att, applicant_id, customer_reference) => {
    
    let payload = 
        {
            "Customer": {
                "CustomerReference": applicant_id,
                "Firstname": att.Firstname,
                "Surname": att.Surname,
                "CustomerStatus": att.CustomerStatus
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
          }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + applicant_id;
        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);

        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

export default {
  executeUpdateCustomerOnlyStatus
};
