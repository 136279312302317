import React, { Component } from 'react';
import { config } from '../Configurator'
import { getCreditSafeBearerToken, getCreditSafeCurrentDirectorsData, CreditSafeDirectorCreditReport, creditSearch } from '../Utils';

class creditSafeDirectorSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customer_reference: null,
            director_data: null,
            disable_credit_search: false,
            forms: config().forms,
        }
    }

    componentDidMount() {
        this.setState({ 'customer_reference': this.props.customer_reference});
    }

    loadDirector(people_id) {

        let that = this;
        let customer_reference= this.state.customer_reference;

        CreditSafeDirectorCreditReport(customer_reference, {'PeopleId': people_id}).then((data) => {
            that.setState({disable_credit_search: false});
            if(data[0]) {
                let url = data[0];
                var link = document.createElement('a');
                link.href = url;
                link.target = "_blank"
                link.dispatchEvent(new MouseEvent('click'));    
            }
        }, ()=> {
            that.setState({disable_credit_search: false});
        })

    }

    creditSearchButtonClick() {

        let that = this;
        that.setState({disable_credit_search: true});

        creditSearch(this.state.customer_reference, 'records').then((records) => {

            getCreditSafeBearerToken().then((bearer_token) => {
                if(bearer_token) {
                    getCreditSafeCurrentDirectorsData(records[0]['id'], bearer_token).then((data) => {
                        that.setState({disable_credit_search: false, director_data: data});
                    });
                } else {
                    alert("Failed to get data");
                    that.setState({disable_credit_search: false});
                }
            });
    

        });
        

    }

    render() {
        const { customer_reference, disable_credit_search, director_data } = this.state;
        return (
            <div>
                <button className="btn btn-outline-dark btn-block btn-sm my-2" disabled={disable_credit_search} onClick={this.creditSearchButtonClick.bind(this)}>
                    <span><img src="/images/loading.gif" height={16} /></span> Director Search
                </button>
                {
                    director_data && director_data.length ?
                    director_data.map(entry => {
                        return <p className='m-0 text-main'><a href="javascript:void(0)" onClick={() => this.loadDirector(entry.id)}>{ entry.name }</a></p>
                    }) : 
                    null
                }
            </div>
        )
    }

}

export default creditSafeDirectorSearch;