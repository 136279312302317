/* let formDataLoop = {

        "heading2": {
          "name": "attributes.0.name2",
          "type": "field",
          "renderer": "inner-text",
          "as": "h2",
          "htmlClass": 'form-group-full',
          'defaultValue': "Loop Data"          
        }

  }; */

let formDataLoop = {};

for(let i=0; i<4; i++) {

  /*
  formDataLoop['heading_' + i] = {
      "name": "attributes.0.loopfields." + i + ".heading",
      "type": "field",
      "renderer": "inner-text",
      "as": "h2",
      "htmlClass": 'form-group-full',
      'defaultValue': "Loop Data KEY " + i
  }*/

  formDataLoop['heading_' + i] = {
      "name": "attributes.0.loopfields." + i + ".heading",
      "type": "field",
      "renderer": "inner-text",
      "as": "h2",
      "htmlClass": 'form-group-full',
      'defaultValue': " Directors / Partners / Sole Trader Details / Shareholders Equal To or Greater Than 25%" 
  }
  formDataLoop['first_name_' + i] = {  "name": "attributes.partners." + i + ".first_name", "label": "Name",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['position_' + i] =   {  "name": "attributes.partners." + i + ".position", "label": "Position",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['email' + i] =       {  "name": "attributes.partners." + i + ".email", "label": "Email",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['mobile_' + i] = {  "name": "attributes.partners." + i + ".mobile", "label": "Mobile",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['dob_' + i] = {  "name": "attributes.partners." + i + ".dob",        "label": "Data of birth",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['shareholding_' + i] = {  "name": "attributes.partners." + i + ".shareholding",        "label": "Shareholding",        "type": "field",        "renderer": "text",        "fieldType": "text"      }
  formDataLoop['home_address_' + i] = {  "name": "attributes.loopfields." + i + ".home_address",        "label": "Home Address",        "type": "field",        "renderer": "textarea",        "fieldType": "textarea"      }
  formDataLoop['previous_address_' + i] = {  "name": "attributes.partners." + i + ".previous_address",        "label": "Previous Address",        "type": "field",        "renderer": "textarea",        "fieldType": "textarea"      }
  formDataLoop['years_' + i] = {  "name": "attributes.partners." + i + ".years",        "label": "Time at this address: Years",        "type": "field",        "renderer": "react-select", "options": { '0':'0', '1':'1', '2':'2', '3':'3', '4':'4', '5':'5', '6':'6', '7':'7', '8':'8', '9':'9', '10':'10+' },        "fieldType": "react-select", "htmlClass": 'form-group-quater'      }
  formDataLoop['months_' + i] = {  "name": "attributes.partners." + i + ".months",        "label": "Months",        "type": "field",        "renderer": "react-select", "options": { '0':'0', '1':'1', '2':'2', '3':'3', '4':'4', '5':'5', '6':'6', '7':'7', '8':'8', '9':'9', '10':'10', '11':'11' },        "fieldType": "react-select", "htmlClass": 'form-group-quater'      }

}

export { formDataLoop };