import React from 'react';
import CustomersTable from '../components/CustomersTable';
import Navbar from '../components/Navbar';

export default function Customers() {
    return (
        <div>
            <Navbar />
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <CustomersTable />
                    </div>
                </div>
            </div>
        </div>
    );
}