import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, currency, getStaff } from '../Utils';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class CasesTableProspect extends Component {

    constructor(props) {
        super(props);
        this.userChange = this.userChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.state = {
            loading: true,
            users: [],
            user: "",
            cache: {
                key: null,
                result: []
            },
            pagination: {
                rpp: this.props.rpp ? parseInt(this.props.rpp) : 12, // 12
                current_page: 1,
                total_results: 0,
            },
            job_states: [],
            job_state: null,
            all_chunks_loaded: false,
            current_chunk: 0,
            cases_data: [], // to hold all possible cases
            cases: [], // filtered cases only
            filter: ''
        } 
    }

    userChange(event) {
        let {name: fieldName, value} = event.target;
        this.filterResults();
    }

    handleFilterChange = (e) => {
        this.setState({ filter: e.target.value });
    }

    componentDidMount() {
        getStaff(false).then(response => {
            let users = response
            if(users===null) {
                users = []
            }
            this.setState({ job_state: 'Prospect', user: localStorage.getItem('bo_email'), users: users }, ()=>{  
                this.load();
            })
        })
    }

    onPageChange(v) {
        let pagination = this.state.pagination;
        pagination.current_page = v;
        this.setState({pagination: pagination},  () => {
            this.filterResults();
        })
    }

    load() {

        if(this.state.all_chunks_loaded) {
            return false;
        }

        // let that = this
        // let searchType = ""
        // let searchText = ""
        // let filter = this.state.filter
        // let pagination = this.state.pagination
        
        // let start = ((pagination.current_page - 1) * pagination.rpp) + 1
        // let end = (((pagination.current_page - 1) * pagination.rpp) + pagination.rpp)
        
        // let filter_user = this.state.user

        // if(filter_user) {
        //    searchType = "case assignment"
        //    searchText = filter_user
        // }

        // if(filter) {
        let start = this.state.current_chunk * 30
        let end = start + 30
        // }

        // alert('loading chunk ' + this.state.current_chunk + " for " + start + ' , ' + end);

        // let caching_key = searchType + '_' + searchText + '_' + start + '_' + end

        //if(caching_key == this.state.cache.key) {
        //    that.setState({
        //        loading: false,
        //        cases: this.filterResults(this.state.cache.result, start, end, filter),
        //    })
        //    return true
        //}
        
        let searchForUser = this.state.user;
        if (searchForUser==='all') searchForUser = "";
        if (searchForUser==='current') {
            this.setState({user: localStorage.getItem('bo_email')})
            searchForUser = localStorage.getItem('bo_email');
        }

        if(start===0) {
            console.log('filtering for ' + searchForUser)
            this.setState({ loading: true });
        }

        axios.post(config().base_url + 'SearchCasesBySearchTypeAndUserPermissionsJSONV2', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "pRequest": {
                Status: "",
                SearchType: "Case Assignment",
                SearchText: searchForUser,
                startDate: "2007-01-01",
                endDate: "2100-01-01",
                recordStartNo: start,
                recordEndNo: end,
                OrderType: [
                    {
                        OrderOn: "code",
                        OrderFlow: "desc"
                    }
                ]
            }
        })
        .then(response => {

            this.setState({
                loading: false,
                current_chunk: this.state.current_chunk + 1,
                cases_data: this.state.cases_data.concat(response.data.d.Records)
                // cases: this.filterResults(response.data.d.Records, start, end, filter),
                // cache: { key: caching_key, result: response.data.d.Records }
            }, () => {
                this.filterResults();                
                if(response.data.d.Records.length>0 && this.state.current_chunk<100) {
                    this.load();
                } else {
                    console.log('all data loaded (' + this.state.cases_data.length + ')');
                    this.setState({all_chunks_loaded: true});
                }
            })
        })
        .catch(error => {
            this.setState({loading: false, all_chunks_loaded: true});
            console.log(error);
        });
    }

    next() {
        let pagination = this.state.pagination
        pagination.current_page ++
        this.setState({pagination: pagination}, () => {
            this.filterResults()
        })
    }

    prev() {
        let pagination = this.state.pagination
        if(pagination.current_page>1) {
            pagination.current_page --
            this.setState({pagination: pagination}, () => {
                this.filterResults()
            })
        }
    }

    filterResults() {

        let filter_user = this.state.user;
        let filter_text = this.state.filter;
        let filter_status = "Prospect";

        let arr = []; // local result array 
        let cp = this.state.pagination.current_page;
        let rpp = this.state.pagination.rpp;

        // console.log(this.state.pagination);

        for(let i=0; i<this.state.cases_data.length; i++) {

            let filter1 = filter_status ? this.state.cases_data[i].JobStatus===filter_status : true;
            // let filter2 = this.detectUser(this.state.cases_data[i], this.state.user);
            let filter3 = filter_text ? this.fullTextSearch(this.state.cases_data[i], filter_text) : true;

            if(filter1 /* && filter2 */ && filter3) {
            //let r = this.renderRow(this.state.cases_data[i], filter);
            //if(r) {
                arr.push(this.state.cases_data[i])
            //}
            }
        }

        
        // console.log((cp-1)*rpp, cp*rpp)
        let pagination = this.state.pagination
        pagination.total_results = arr.length

        // reset page to 1 if current page > total pages available
        let total_page_available = Math.ceil(arr.length / pagination.rpp)
        if(pagination.current_page > total_page_available) {
            pagination.current_page = 1;
        }

        this.setState({
            pagination: pagination,
            cases: arr.slice((cp-1)*rpp, cp*rpp)
        })
    }

    detectUser(record, state_user) {
        let found_user = false;
        ['CaseOwner', 'CaseUnderWriter', 'CaseProcessor', 'CaseAdditionalUser1', 'CaseAdditionalUser2'].forEach(key => {
            if(typeof record[key] != 'undefined') {
                if(state_user==='all' || state_user == record[key]) {
                    found_user = true;
                }
                // if(!users.includes(records[i][key]) && records[i][key]) {
                //    users.push(records[i][key]);
                // }
            }    
        });
        return found_user;
    }

    fullTextSearch(entry, txt) {
        if(txt) {
            txt = txt.toUpperCase();
            let condition_filter = false
            const values_to_compare = [entry.JobReference, convertDate(entry.DateCreated) + '', entry.CustomerNames, entry.Term + '', entry.JobStatus + ''];
            values_to_compare.forEach(element => {
                if (element && element.toUpperCase().indexOf(txt) != -1) condition_filter = true;
            });
            return condition_filter
        }
        return true
    }

    render() {
        const { cases, loading, pagination } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8"><h4 className="font-weight-bold">Prospects</h4></div>
                    <div className='col-md-2'>
                        <input className="filters" type="text" placeholder='Search' value={this.state.filter} onChange={(e) => this.setState({filter: e.target.value}, function(){ this.filterResults() })} />
                    </div>
                    <div className="col-md-2">
                        <select style={{ maxWidth: '100%' }} className="filters" value={this.state.user} onChange={(e) => this.setState({user: e.target.value, all_chunks_loaded: false, cases_data: [], current_chunk: 0}, function(){ this.load() })}>
                            <option value="all">All</option>
                            <option value="current">Current User</option>
                            {this.state.users.map((v) => <option key={v.value} value={v.value}>{v.label}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th width="10%">Case Ref.</th>
                                        <th width="10%">Date</th>
                                        <th width="30%">Customer</th>
                                        <th width="5%" className='text-right'>Term</th>
                                        <th width="15%" className='text-right'>Total Advance</th>
                                        <th width="10%">Status</th>
                                        <th width="10%"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        cases.length ?
                                        cases.map((entry, key) => {
                                            return (true) ? 
                                            <tr key={key}>
                                                <td>{ entry.JobReference }</td>
                                                <td>{ convertDate(entry.DateCreated) }</td>
                                                <td>{ entry.CustomerNames }</td>
                                                <td className='text-right'>{ entry.Term }</td>
                                                <td className='text-right'>{ currency(entry.LoanAmount) }</td>
                                                <td>{ entry.JobStatus }</td>
                                                <td className='text-center'>
                                                    <Link to={ '/case/' + entry.JobReference } className="btn-more"></Link>
                                                </td>
                                            </tr> : null
                                        }) : 
                                        null
                                    }
                                </tbody>
                            </table>

                            <Pagination className="pagination-bar" currentPage={pagination.current_page} totalCount={pagination.total_results} pageSize={pagination.rpp} onPageChange = {this.onPageChange} />

                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default CasesTableProspect;
