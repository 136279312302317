import axios from 'axios';
import { config } from '../Configurator'
// import { multiplyFields, convertDateForApi, convertToDouble } from '../Utils'
import alertify from 'alertifyjs';

const endpoint = "CreateSource";

export const executeCreateSourceFromLead = (att) => {
    
    let payload = {
        "pRequest": {
                    "SourceCode": "",
                    "SourceName": att.Source,
                    "SourceStatus": "Active",
                    "SourceStatusDesc": null,
                    "Contact": "",
                    "Salutation": "",
                    "WebSite": null,
                    "Telephone": null,
                    "Mobile": null,
                    "Email": "",
                    "Fax": null,
                    "Notes": null,
                    "SourceCategory": att.SourceCategory,
                    "SourceCategoryDesc": "",
                    "EnableCommission": true,
                    "SourceArea": "",
                    "SourceAreaDesc": null,
                    "SourceRegion": att.SourceRegion,
                    "SourceRegionDesc": null,
                    "SourceReference": null,
                    "SourceRep": null,
                    "SourceLead": null,
                    "SourceStatementText": null,
                    "SourceCreatedDate": null,
                    "Additional1UserName": "",
                    "Additional2UserName": null,
                    "SourceBrokerNetworks": [
                        {
                            "BrokerNetworkCompanyName": ""
                        },
                        {
                            "BrokerNetworkCompanyName": ""
                        }
                    ],
                    "DistanceFromCurrent": 0,
                    "SourceAddress": {
                        "HouseName": null,
                        "HouseNumber": null,
                        "Address1": "",
                        "Address2": "",
                        "Address3": "",
                        "Address4": "",
                        "PostCode": null
                    },
                    "SourceCaseDetail": {
                        "TotalCases": 0,
                        "TotalCompletedCases": 0,
                        "TotalCancelledCases": 0,
                        "TotalInProgressCases": 0,
                        "TotalLoan": 0
                    },
                    "SourceTypes": [{"SourceType":att.SourceType}],
                    "SourceAffiliates" : [{
                        "sourceAffiliateCode": "",
                        "sourceAffiliateName": att.SourceAffiliate,
                        "contact": null,
                        "webSite": null,
                        "telephone": "",
                        "fax": null,
                        "mobile": null,
                        "email": att.AffiliateEmail,
                        "salutation": null,
                        "sourceAffiliateStatus": null,
                        "sourceAffiliateStatusDesc": null,
                        "sourceAffiliateArea": null,
                        "sourceAffiliateAreaDesc": null,
                        "sourceAffiliateRegion": null,
                        "sourceAffiliateRegionDesc": null
    }]
                },
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

export default {
    executeCreateSourceFromLead
};
