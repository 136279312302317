// import moment from 'moment';
// import axios from 'axios';
// import { config } from './Configurator'
import { convertDate } from './Utils';

export const uniqueJobStates = (records) => {

    let job_states = [];
    for(let i=0; i < records.length; i++) {
        let js = records[i].JobStatus;
        if(js && !job_states.includes(js)) {
            job_states.push(js);
        }
    }
    job_states.unshift('');
    return job_states.sort();
}

export const entryContainsString = (entry, txt) => {
    if(txt) {
        txt = txt.toUpperCase();
        let condition_filter = false
        const values_to_compare = [entry.JobReference, convertDate(entry.DateCreated) + '', entry.CustomerNames, entry.Term + '', entry.JobStatus + ''];
        values_to_compare.forEach(element => {
            if (element && element.toUpperCase().indexOf(txt) != -1) condition_filter = true;
        });
        return condition_filter
    }
    return true
}

export const removeDuplicates = (arr) => {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

export const findUserInEntry = (entry, user, i) => {
    if(!user || user==='all') return true;
    if(entry.CaseOwner===user) return true;
    if(entry.CaseUnderWriter===user) return true;
    if(entry.CaseProcessor===user) return true;
    if(entry.CaseAdditionalUser1===user) return true;
    if(entry.CaseAdditionalUser2===user) return true;
    return false;
}