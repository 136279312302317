import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../components/Loading';
import { config } from '../Configurator'

class ArticlesRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            top_article: {},
            articles: [] 
        }
    }

    componentDidMount() {
        axios.get('https://evelocity.info/projects/articles-api/public/api/articles?client_id=1' )
        .then(response => {
            // console.log(response.data.d.Records);
            this.setState({
                top_article: response.data.data[0],
                articles: response.data.data.splice(1,3),
                loading: false
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }


    render () {
        const { top_article } = this.state;
        const { articles, loading } = this.state;
        return(
        <div>
            <h2>What's New</h2>
            {
                loading ? <Loading></Loading> : null
            }
            {
                articles.length ? 
            <div className="card">
                <div className="card-body">
                    <img src={ top_article.image_src } className="img-fluid" alt="Article illustration" />
                    <h3 className="mt-3 mb-2">{ top_article.title }</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                </div>
                <div className="card-body">

                    {                
                        articles.length ?
                        articles.map(entry => {
                            return <div className="row mb-3"  key={ entry.id }>
                                <div className="col-4">
                                    <img src={ entry.image_src } className="img-fluid" alt="Article illustration" />
                                </div>
                                <div className="col-8">
                                    <h3 className="mt-0 mb-1">{ entry.title }</h3>
                                    <p>{ entry.short }</p>
                                </div>
                            </div>
                        }): null 
                    }

                </div>
            </div> : null
            }

        </div>
    )
}

}

export default ArticlesRecent;