import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'

class DocumentsSharepoint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            context: 'cases',
            loading: true, 
            xml: [],
            nav_history: [],
            applicant_id: null,
            base_folder: '/sites/BO-TestSite/Shared%20Documents',
            customer_reference: null,
            case_id: null,
            folder_mode: null,
            forms: config().forms,
            folder: 'index',
            empty_folder: false
        }
    }

    componentDidMount() {

        let f = this.props.folder;

        let context = this.props.context ?? 'cases';
        this.setState({ 
            'context': context, 
            'folder': (f ? f : 'index'),
            'case_id': this.props.caseId,
            'folder_mode': {
                'init': false,
                'have_data': null,
                'folder': "CA001789" // this.props.caseId  
            },
            'applicant_id': this.props.applicant_id, 
            'customer_reference': this.props.customer_reference}, function(){
                this.initializeData();
            });
    }

    initializeData()
    {
        this.realResponse('index');
    }

    realResponse(target='index', from_nav_back=false)
    {
        let that = this;

        let nav_history = this.state.nav_history;
        if(!from_nav_back && this.state.folder_mode.init && target!='index') {
            nav_history.push(target);
        }
        this.setState({'nav_history': nav_history});

        that.setState({'loading': true}, () => {

            if(target=='index') {
                target = "/sites/BO-TestSite/Shared%20Documents";
            } 
    
            // /_api/web/GetFolderByServerRelativeUrl('/sites/BO-TestSite/Shared%20Documents')?$expand=Folders,Files

            axios.get("https://crm.evelocity.info/sharepoint-api/?param=" + "_api/web/GetFolderByServerRelativeUrl('" + encodeURIComponent(target) + "')?$expand=Folders,Files").then(function (response) {
                
            
                if(Array.isArray(response.data.d.Files.results)) {
                    response.data.d.Files.results.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
                }
                if(Array.isArray(response.data.d.Folders.results)) {
                    response.data.d.Folders.results.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
                }

                that.setState({'xml': response.data.d, 'folder': target, 'loading': false}, () => {
                    that.handlePostLoad();
                });
    
            });
        });
    }

    handlePostLoad() {

        if(this.state.folder_mode.folder!==null && this.state.folder_mode.init===false && typeof(this.state.xml.Folders) !== 'undefined' && Array.isArray(this.state.xml.Folders.results)) {

            // look for specific folder
            for(let i=0; i<this.state.xml.Folders.results.length; i++) {
                let folder_name = this.state.xml.Folders.results[i]['Name'];
                if(folder_name.includes(this.state.folder_mode.folder)) {
                    this.realResponse(this.state.xml.Folders.results[i]['ServerRelativeUrl']);
                    this.setState({base_folder: this.state.xml.Folders.results[i]['ServerRelativeUrl'], nav_history: [this.state.xml.Folders.results[i]['ServerRelativeUrl']], folder_mode: { have_data: true, init: true }});
                    return true;
                }
            }
            this.setState({xml: [], folder_mode: { have_data: false, init: true }});

        }
    }

    handleClick(targ, from_nav_back=false) {
        // click on folder
        this.realResponse(targ, from_nav_back);
    }

    handleGoBack() {


        let history = this.state.nav_history;
        if(history.length==0) {
            return false;
        } else {
            history = history.slice(0,-1);
            this.setState({'nav_history': history}, function(){
                this.handleClick(history.at(-1), true);
            });
        }        
    }

    handleFileClick(file) {
        let url = ("https://rewardcf.sharepoint.com.mcas.ms" + file);
        window.open(url, '_blank').focus();
    }

    convertXmlToJson(xml)
    {

        try {
            var obj = {};
            if (xml.children.length > 0) {
            for (var i = 0; i < xml.children.length; i++) {
                var item = xml.children.item(i);
                var nodeName = item.nodeName;
        
                if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = this.convertXmlToJson(item);
                } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
        
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(this.convertXmlToJson(item));
                }
            }
            } else {
            obj = xml.textContent;
            }
            return obj;
        } catch (e) {
            console.log(e.message);
        }          
    }

    render() {
        const { xml, loading, folder, base_folder } = this.state;
        return (
            <div>

                <div className="card">
                    <div className="card-body">


                    {
                        loading ? <Loading></Loading> :

                        ((typeof (xml.Folders) !== 'undefined' 
                            && Array.isArray(xml.Folders.results)) 
                            || (typeof(xml.Files) !== 'undefined' 
                            && Array.isArray(xml.Files.results))) ?
                        <table className="table">
                            <thead>
                            <tr>
                                <th>File/Folder Name</th>
                                <th>Modified Date</th>
                            </tr>
                            </thead>
                            <tbody>

                            {(folder!=base_folder) ? <tr><td><a href="javascript:void(0)" onClick={() => this.handleGoBack()} > &larr; Back </a></td></tr> : ''}

                            {
                                Array.isArray(xml.Folders.results) && xml.Folders.results.length ?
                                xml.Folders.results.map(entry => {
                                    return (true) ? <tr key={entry.id}>
                                        <td><a className="text-dark" href="javascript:void(0)" onClick={() => this.handleClick(entry.ServerRelativeUrl)} ><i className="fa fa-folder"></i> { entry.Name } </a></td>
                                        <td>{ entry.TimeLastModified.replace('T', ' ').replace('Z', ' ') }</td>
                                    </tr> : ''
                                }) : null
                            }
                            {
                                Array.isArray(xml.Files.results) && xml.Files.results.length ?
                                xml.Files.results.map(entry => {
                                    return (true) ? <tr key={entry.id}>
                                        <td><a href="javascript:void(0)" onClick={() => this.handleFileClick(entry.ServerRelativeUrl)} >{ entry.Name }</a></td>
                                        <td>{ entry.TimeLastModified.replace('T', ' ').replace('Z', ' ') }</td>
                                    </tr> : ''
                                }) : null
                            }
                            

                            </tbody>
                        </table> : <p>No linked folder found</p>
                    }

                    </div>
                </div>

            </div>
        )
    }
}

export default DocumentsSharepoint;