import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, orderResult } from '../Utils';
import Loading from '../components/Loading';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class CustomersTable extends Component {

    constructor(props) {
        super(props)
        this.userChange = this.userChange.bind(this);
        this.state = {
            loading: true,
            user: null,
            customers: []
        } 
    }

    userChange(event) {
        let {name: fieldName, value} = event.target;
        this.load(value);
    }

    loadCustomers() {
        this.load(null);
    }

    load(user) {
        if(user===null) user = localStorage.getItem('bo_email');
        this.setState({'loading': true});
        axios.post(config().base_url + 'GetCustomerListForUserAndCustomerTypeSortOrderJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "SearchOptions": {
              "SortType": "Decending",
              "searchText": user,
              "isTelesalesRequest": "false",
              "endDate": "",
              "searchType": "Owner",
              "SortBy": "Code",
              "customerTypeCode": "",
              "customerStatusCode": "",
              "recordStartNo": "0",
              "customerCode": "",
              "startDate": "",
              "recordEndNo": "10000"
            }
          })
        .then(response => {
            //  alert("loaded");
            this.setState({customers: orderResult(response.data.d.Records, "CustomerReference", "desc"), loading: false});
        })
        .catch(error => {
            this.setState({loading: false});
            console.log(error);
        });
    }

    componentDidMount() {
        this.loadCustomers();
    }

    render() {
        const { customers, loading } = this.state;
        return (
            <div>


                {/* <div className="row d-none">
                    <div className="col-md-4">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                            <option value="select">Select</option>
                            <option value="Apples">Apples</option>
                            <option value="Mangoes">Mangoes</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        { this.state.user }
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-10"><h4 className="font-weight-bold">Customers</h4></div>
                    {/* Peregrine Finance Users */}
                    <div className="col-md-2">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                        <option value="">Current User</option>
                        <option value="adam.spenceley">Adam Spenceley</option>
                        <option value="jaye.rhodes">Jaye Rhodes</option>
                        <option value="robyn.welford">Robyn Welford</option>
                        <option value="peregrine.fiance">Peregrine Finance</option>
                        </select>
                    </div>
                    {/* Reward Finance Users */}
                    {/* <div className="col-md-2">
                        <select className="filters" id="user" onChange={this.userChange} value={this.state.user}>
                        <option value="">Current User</option>
                        <option value="alan.sanderson">Alan Sanderson</option>
                        <option value="bethany.hunnebell">Bethany Hunnebell</option>
                        <option value="chris.ibbetson">Chris Ibbetson</option>
                        <option value="claire.mitchell">Claire Mitchell</option>
                        <option value="clive.briggs">Clive Briggs</option>
                        <option value="david.baddiel">David Baddiel</option>
                        <option value="david.harrop">David Harrop</option>
                        <option value="david.jones">David Jones</option>
                        <option value="elizabeth.harrison">Elizabeth Harrison</option>
                        <option value="gemma.wright">Gemma Wright</option>
                        <option value="joanna.haigh">Joanna Taylor</option>
                        <option value="john.gallagher">John Gallagher</option>
                        <option value="karen.paige">Karen Paige</option>
                        <option value="karen.vincent">Karen Vincent</option>
                        <option value="louise.donnelly">Louise Donnelly</option>
                        <option value="luke.drysdale">Luke Drysdale</option>
                        <option value="mike.calvert">Mike Calvert</option>
                        <option value="nick.battersby">Nick Battersby</option>
                        <option value="nick.smith">Nick Smith</option>
                        <option value="parisa.lian">Parisa Lian</option>
                        <option value="paula.jones">Paula Jones</option>
                        <option value="rachel.lee">Rachel Lee</option>
                        <option value="russell.holt">Russell Holt</option>
                        <option value="simon.micklethwaite">Simon Micklethwaite</option>
                        <option value="steve.noble">Steve Noble</option>
                        <option value="tim.stafford">Tim Stafford</option>
                        <option value="tom.flannery">Tom Flannery</option>
                        <option value="tom.newhouse">Tom Newhouse</option>
                        </select>
                    </div> */}
                    {/* Lendology Users */}
                    <div className="col-md-2">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                        <option value="">Current User</option>
                        <option value="amanda.markham">Amanda Markham</option>
                        <option value="andy.meldrum">Andy Meldrum</option>
                        <option value="anna.osborne">Anna Osborne</option>
                        <option value="ellie.lister">Ellie Lister</option>
                        <option value="emma.lower">Emma Lower</option>
                        <option value="harriet.delrey">Harriet Delrey</option>
                        <option value="kristian.hawkes">Kristian Hawkes</option>
                        <option value="Laura.Hill">Laura Hill</option>
                        <option value="martine.bryant">Martine Bryant</option>
                        <option value="Max.Beale">Max Beale</option>
                        <option value="philippa.cooke">Philippa Cooke</option>
                        <option value="shannon.nutkins">Shannon Nutkins</option>
                        <option value="tony.hawkings">Tony Hawkings</option>
                        </select>
                    </div>
                    {/* <div className="col-md-4">
                        { this.state.user }
                    </div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/2">Quick Quote</Link></div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div> */}
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Created</th>
                                    <th>Company</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    customers.length ?
                                    customers.map(entry => {
                                        return <tr key={entry.CustomerReference}>
                                            <td>{ entry.CustomerReference }</td>
                                            <td>{ convertDate(entry.DateCreated) }</td>
                                            <td>{ entry.CompanyName }</td>
                                            <td>{ entry.Firstname } { entry.Surname }</td>
                                            <td>{ entry.CustomerAddress.Address1 } { entry.CustomerAddress.PostCode }</td>
                                            <td>{ entry.CustomerStatus }</td>
                                            <td>
                                                <Link to={ '/customer/' + entry.CustomerReference } className="btn-more"></Link>
                                            </td>
                                        </tr> 
                                    }) : 
                                    null
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default CustomersTable;