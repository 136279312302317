import React from 'react';
import Navbar from '../components/Navbar';
import { formData } from '../data/formData';
import { Form } from '@flipbyte/formik-json';
import { boApiSendProposal } from '../Utils';
 
export default function Dynamic() {

      return (
        <div>
            <Navbar />
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12">
                        <div className="dynamic-form">

                            <div className="dynamic-form-body">
                            <h1>Proposal Form</h1>

                            <Form 
                            onSubmit={(data, formik) => { 
                                boApiSendProposal(data, formik, 1, 1);
                            }}
                            validator={() => ({})}                        
                            schema={formData}></Form>
                            </div>

                            
                            <hr />
                            
                            <div className="dynamic-form-footer">

                            <h2>Certificate</h2>
                                <p>We certify that to the best of our knowledge and belief the information we have given you is correct and we are not aware of any matters or circumstances which we
    have not disclosed to you which might influence your decision. We certify that there are no writs, summonses, judgments, petitions, winding up orders, or receiving
    orders in bankruptcy outstanding or threatened against this company/firm or its directors/partners.</p>

                                <h2>Data Protection Notice</h2>
                                <p>IMPORTANT NOTICE - USE OF INFORMATION about your business’s directors, shareholders, beneficial owners or other persons of significant control, personal
    guarantors, members and partners (hereafter “Individuals”). References to “you” shall mean the business to which we are providing services, as set out on the first
    page of this Proposal Form. References to “we”, “us”, “our” and “Reward” mean Reward Capital Limited (company number 09432492) or Reward Invoice Finance
    Limited (company number 09432479), as applicable depending on which company is providing services to you.</p>
                                <p>(1)You must provide us with a list of all Individuals and their current residential addresses and must promptly update us with any future changes ofthese details. In
    addition, you must provide us with any information which we reasonably request to enable us to comply with our obligations underany laws relating to the prevention
    of money laundering and terrorist financing</p>
                                <p>(2)The ways in which we will process your personal data (both “process” and “personal data” having the meanings set out in the Data ProtectionAct 1998 and, in
    substitution from 25 May 2018, the General Data Protection Regulation 2016 (“GDPR”)) are set out below. By signing this form,you confirm that you consent to such
    processing by us. We require express consent from each of the Individuals to enable us to process theirpersonal data. By signing this form each Individual confirms
    that it consents to Reward processing its personal data pursuant to this ProposalForm. Until we have express consent from each Individual we may not be able to
    begin working with you or providing services to you. If we need toprocess personal data of an additional Individual in the future, we will need consent from such
    Individual and, if necessary, we will provide you witha further copy of this Proposal Form for signature by that Individual to obtain their consent.We will store and
    process each Individual’s personaldata on our databases or those of our associated companies. We may use and disclose it for the purposes of providing our
    services, in connectionwith insurances obtained by us, for enforcing our rights against any person, to enable our agents advisers or partners to provide us with
    theirservices, to assist our brokers and introducers and to facilitate any transfer of debts, our business or our shares.</p>
                                <p>We will search and record each Individual’s details at licensed credit reference agencies (“CRAs”) and/or fraud prevention agencies (“FPAs”) and will check
    Individuals’ addresses against the electoral register and records kept at Companies House. We will also keep the results of other enquiries we make with CRAs and
    FPAs during your account and give information about your account and how you manage it to CRAs. The CRAs and FPAs will keep details of our searches and any
    information that we provide to them about the business or any dealings with us, and may create a record of the name of your business and its proprietors. If at any
    time we are provided with false or inaccurate information, and fraud is identified or suspected, details will be passed to FPAs where it may be accessed and used by
    law enforcement agencies. We and other organisations that make searches may also access and use this information for the prevention of fraud, money laundering
    or terrorist financing, when checking applications for (or managing) insurance or credit and related accounts, facilities and services, keeping accounts, statistical
    analysis, recovering debt and dealing with claims. The information will also be used for checking details of job applicants and employees. We and other organisations may access and use from other countries the information used for checking details of job applicants and employees. We and other organisations may access
    and use from other countries the information recorded by fraud prevention agencies. If you or any Individual wants to have details of the agencies from whom we
    obtain and to whom we pass information, they should contact the Data Protection Officer at Reward on 0113 246 2700. Each Individual is entitled to receive a copy
    of the information that we hold about them if they apply to us in writing. We may monitor and/or record phone calls for training and/or security purposes.</p>

                                <h2>Declaration</h2>
                                <p>Business Declaration:<br />
    You confirm that the information on this Proposal Form is accurate, complete and correct to the best of your knowledge and belief. You declare that you are
    irrevocably authorised to give us the information in this form and to confirm that all Individuals are listed herein. You confirm that you have read and understood a
    copy of the above notice explaining how we will obtain and use information about you, and that you consent to us processing your personal data. You will advise us
    of any subsequently appointed directors, shareholders, beneficial owners or other persons of significant control, personal guarantors, members and partners and
    that they will be provided with a copy of this notice which they will sign and return to us. You confirm you are authorised to receive all notices from us under the Data
    Protection Act 1998 and, in substitution from 25 May 2018, the GDPR, in respect of all Individuals at the usual business address unless you indicate otherwise.</p>
                                <p>Individuals’ Declaration:<br />
    By completing your details and signing below you confirm that you have read and understood the ways in which we and third parties may process your personal data
    pursuant to the Proposal Form and that you consent to such processing.</p>


                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}