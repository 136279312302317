import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, orderResult, searchCustomFieldsForIndex, getPaginate, getStaff } from '../Utils';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class ProspectsTable extends Component {

    constructor(props) {
        super(props);
        this.userChange = this.userChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.state = {
            loading: true,
            filter: '',
            pagination: {
                rpp: this.props.rpp ? parseInt(this.props.rpp) : 12,
                current_page: 1,
                total_results: 0,
                nb_pages: 0
            },
            user: config().personName,
            users: [],
            customers: []
        } 
    }

    handleFilterChange = (e) => {
        this.setState({ filter: e.target.value });
    }

    userChange(event) {
        this.dataFilterAndPaginate(event.target.value, 1);
    }

    onPageChange(v) {
        this.dataFilterAndPaginate(this.state.user, v);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    dataFilterAndPaginate(user, page) {
        let filtered  = this.filterResults(this.state.customers, user);
        let pagination = this.state.pagination;
        pagination.total_results = filtered.length;
        pagination.current_page = page;
        pagination.nb_pages = Math.ceil(pagination.total_results / pagination.rpp);
        this.setState({'user': user, pagination: pagination, loading: false, customers_filtered: getPaginate(filtered, pagination.rpp, pagination.current_page)});
    }

    loadCustomers() {
        this.load(null);
    }

    renderRow(entry) {
        let user = this.state.user;
        let filter = this.state.filter.toUpperCase();
        let condition_filter = false;
        // main conditions
        let condition_main = ((user && entry.OwnerName==user) || (!user && entry.OwnerName==config().personName ));
        // condition for filtering
        if(condition_main && filter) {
            const values_to_compare = [entry.CustomerReference, convertDate(entry.DateCreated), entry.CompanyName, entry.Firstname, entry.Surname, entry.CustomerAddress.Address1, entry.CustomerAddress.PostCode, entry.CustomerStatus, entry.CustomFieldValue24, entry.OwnerName];
            values_to_compare.forEach(element => {
                if (element && element.toUpperCase().indexOf(filter) != -1) {
                    condition_filter = true;
                }
            });
        } else {
            condition_filter = true;
        }
        // console.log(condition_main && condition_filter);
        return condition_main && condition_filter;
    }
    
    load(user) {
        // if(user===null) user = localStorage.getItem('bo_email');
        this.setState({'loading': true});
        axios.post(config().base_url + 'GetCustomerListForUserAndCustomerTypeSortOrderJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "SearchOptions": {
              "SortType": "Decending",
              "searchText": user,
              "isTelesalesRequest": "true",
              "endDate": "",
              "searchType": "Owner",
              "SortBy": "Code",
              "customerTypeCode": "",
              "customerStatusCode": "Lead",
              "recordStartNo": "0",
              "customerCode": "",
              "startDate": "",
              "recordEndNo": "10000"
            }
          })
          .then(response => {

            let owner_names = [];
            let introducer_firms = [];

            for(let i=0; i<response.data.d.Records.length; i++) {
                let new_name = response.data.d.Records[i]['OwnerName'];
                let intro_firm = response.data.d.Records[i]['SourceName'];
                // if(new_name && !owner_names.includes(new_name)) owner_names.push(new_name);
                if(intro_firm && !introducer_firms.includes(intro_firm)) introducer_firms.push(intro_firm);
            }

            let customers = orderResult(response.data.d.Records, "CustomerReference", "desc");
            this.setState({ customers: customers, customers_filtered: customers /*, users: owner_names.sort() */ }, () => {


            // populate Introducer Type across the table 
            const array = introducer_firms;
            let promises = [];
            for (let i = 0; i < array.length; i++) {
            promises.push(
                axios.post(config().base_url + 'GetSource', { "UserInfo": { "Username": localStorage.getItem('bo_email'), "Password": localStorage.getItem('bo_password') }, "pRequest": { "Status": "", "SortType": "Desc", "SortBy": "Ref", "SearchType": "source", "SearchText": array[i], "RecordStartNo": "1", "RecordEndNo": "10000" } }) .then(response => { if(typeof response.data.d.Records[0].SourceTypes[0] != 'undefined') { return [array[i], response.data.d.Records[0].SourceTypes[0].SourceType]; } else {  return null; } }) .catch(error => { return null; })
                )
            }
            Promise.all(promises)
            .then(responses => {
                if(Array.isArray(responses)) {
                    let customers = this.state.customers;
                    for(let j=0; j<responses.length; j++) {

                        if(responses[j]) {
                            let firm = responses[j][0];
                            let type = responses[j][1];
                            for(let jj=0; jj<customers.length; jj++) {
                                if(customers[jj]['SourceName']==firm) {
                                    customers[jj]['SourceType'] = type;
                                    // console.log(customers[jj]['SourceName'] + ' set to ' + type);
                                }
                            }    
                        }

                    }

                    this.dataFilterAndPaginate(this.state.user, 1);
                }
            });
        });

        })
        .catch(error => {
            this.setState({loading: false});
            console.log(error);
        });
    }

    filterResults(customers, user=null)
    {
        let resp = [];
        if(user===null) {
            let user = this.state.user;
        }
        let job_state = this.state.job_state;
        for(let i=0; i<customers.length; i++) {
            let entry = customers[i];
            if (((user && entry.OwnerName==user) || (user && user=='all') || (!user && entry.OwnerName==config().personName )) && (job_state && entry.CustomerStatus===job_state || !job_state)) {
                resp.push(entry);
            }
        }
        return resp;
    }

    componentDidMount() {


        getStaff(true).then(response => {
            let users = response;
            if(users===null) {
                users = []
            }
            this.setState({ users: users }, ()=>{  
                this.loadCustomers();
            })
        })

    }

    render() {
        const { customers_filtered, loading, users, user, job_state, pagination } = this.state;
        return (
            <div>

                {/* <div className="row d-none">
                    <div className="col-md-4">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                            <option value="select">Select</option>
                            <option value="Apples">Apples</option>
                            <option value="Mangoes">Mangoes</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        { this.state.user }
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-8"><h4 className="font-weight-bold">Leads</h4></div>

                    <div className='col-md-2'>
                        <input class="filters" type="text" placeholder='Search' value={this.state.filter} onChange={this.handleFilterChange} />
                    </div>

                    <div className="col-md-2">
                        <select id="user" onChange={this.userChange} className="filters" value={this.state.user}>
                            <option value="all"></option>
                            <option value="">Current User</option>
                            { users.map((e, key) => {
                                return <option key={e} value={e}>{e}</option>;
                            })}
                        </select>
                    </div>

                    {/* <div className="col-md-4">
                        { this.state.user }
                    </div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/2">Quick Quote</Link></div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div> */}
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <div>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th width="10%">Customer Reference</th>
                                    <th width="10%">Date Received</th>
                                    <th width="20%">Prospect Name</th>
                                    <th width="10%">Introducer Name</th>
                                    <th width="15%">Introducer Firm</th>
                                    <th width="15%">Introducer Type</th>
                                    {/* <th width="15%">Product</th> */}
                                    <th width="15%">Line Size</th>
                                    <th width="10%">Reward Contact</th>
                                    <th width="5%"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    customers_filtered.length ?
                                    customers_filtered.map(entry => {
                                        return (this.renderRow(entry)) ?
                                        <tr key={entry.CustomerReference}>
                                            <td>{ entry.CustomerReference }</td>
                                            <td>{ convertDate(entry.DateCreated) }</td>
                                            <td>{ entry.CompanyName } { entry.Firstname } {entry.Surname}</td>
                                            <td>{ entry.SourceAffiliateName }</td>
                                            <td>{ entry.SourceName }</td>
                                            <td>{ entry.SourceType }</td>
                                            {/* <td>{ entry.CustomFieldValue23 = searchCustomFieldsForIndex(entry.CustomFields, 23) }</td> */}
                                            <td>{ entry.CustomFieldValue24 = searchCustomFieldsForIndex(entry.CustomFields, 24) }</td>
                                            <td>{ entry.OwnerName }</td>
                                            <td>
                                                <Link to={ '/prospect/' + entry.CustomerReference } className="btn-more"></Link>
                                            </td>
                                        </tr>  : null
                                    }) : 
                                    null
                                }
                            </tbody>
                        </table>
                        <Pagination className="pagination-bar" currentPage={pagination.current_page} totalCount={pagination.total_results} pageSize={pagination.rpp} onPageChange = {this.onPageChange} />
                        </div>

                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default ProspectsTable;
