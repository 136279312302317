import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency, orderResult } from '../Utils';
import Loading from '../components/Loading';
import { config } from '../Configurator'

class CasesRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cases: [],
            loading: true
        }
    }

// Results filtered by Live in JobStatus

    componentDidMount() {

        let status = this.props.status ?? "Application";

        axios.post(config().base_url +  'SearchCasesBySearchTypeAndUserPermissionsJSONV2', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "pRequest": {
                "Status": "Application",
                "SearchType": "case assignment",
                "SearchText": localStorage.getItem('bo_email'),
                "startDate": "2007-01-01",
                "endDate": "2025-01-01",
                "recordStartNo": 1,
                "recordEndNo": 20,
                "OrderType": [
                    {
                        "OrderOn": "code",
                        "OrderFlow": "desc"
                    }
                ]
            }
    } )
        .then(response => {

            let filteredArray = response.data.d.Records.filter(function(itm){
                return (itm.JobStatus == status);
            });
            let r = orderResult(filteredArray, 'JobReference', 'desc');
            this.setState({loading: false, cases: r.slice(0,3)});
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    render() {
        const { cases, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        {
                            loading ? <Loading></Loading> : null
                        }
                        {
                            cases.length ?
                            cases.map(entry => {
                                return <div key={ entry.JobReference }>
                                <div className="card mb-3">
                                <div className="card-body row">
                                    <div className="col-3">Customer:<br /><strong>{entry.CasesCustomerNames}</strong></div>
                                    <div className="col-2">Case ref:<br /><strong>{entry.JobReference}</strong></div>
                                    <div className="col-2">Amount:<br /><strong>{currency(entry.LoanAmount)}</strong></div>
                                    <div className="col-2">Term:<br /><strong>{entry.Term}</strong></div>
                                    <div className="col-2">Status:<br /><strong>{entry.JobStatus}</strong></div>
                                    <div className="col-1">
                                        <Link to={ '/livecase/' + entry.JobReference } className="btn-more"></Link>
                                    </div>
                                </div>
                            </div>   
                            </div> 
                            }) : 
                            null
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="digitus-view-all">
                        <Link to='/cases'>View All</Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default CasesRecent;