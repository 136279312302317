import axios from 'axios';
import { config } from './../Configurator'
import { convertDateForApi, convertToDouble } from './../Utils'
import alertify from 'alertifyjs';

const endpoint = "CreateNewProspectV2";

export const executeCreateNewProspectV2 = (att) => {
    
    let payload = {
        "userInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "customer": {
            "Firstname": att.CompanyName,
            "Surname": "",
            "CompanyName": att.CompanyName,
            "Telephone": "",
            "Email": "",
            "DateOfBirth": convertDateForApi(""),
            "CustomerStatus": "Prospect",
            "B2BCustomer": "true",
            "AltSalutation": att.AltSalutation,
            "InvoiceTermName":"",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": "",
                "Address2": "",
                "Address3": "",
                "Address4": "",
                "PostCode": ""
            }
        },
        "ClientPassword": ""
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

export default {
    executeCreateNewProspectV2
};
