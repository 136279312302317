import React, { Component } from 'react';
import { config } from '../Configurator'
import { creditSafeDirectorSearchFunction, CreditSafeDirectorCreditReport } from '../Utils';

class CreditSafeCompanySearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customer_reference: null,
            first_name: "",
            last_name: "",
            disable_credit_search: false,
            forms: config().forms
        }
    }

    componentDidMount() {
        this.setState({ 
            'customer_reference': this.props.customer_reference,
            'first_name': this.props.first_name,
            'last_name': this.props.last_name
        });
    }

    creditSearchButtonClick() {

        let that = this;
        that.setState({disable_credit_search: true});
        let customer_reference= this.state.customer_reference;
        creditSafeDirectorSearchFunction(customer_reference, {"firstName": this.state.first_name, "lastName": this.state.last_name}).then((data) => {
            if(data && typeof(data[0]) != 'undefined') {
                let people_id = data[0].peopleId;

                CreditSafeDirectorCreditReport(customer_reference, {'PeopleId': people_id}).then((data) => {
                    that.setState({disable_credit_search: false});
                    if(data[0]) {
                        let url = data[0];
                        var link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank"
                        // link.download = 'CreditSafeCompanyCreditReport_' + customer_reference + '.pdf';
                        link.dispatchEvent(new MouseEvent('click'));
            
                    }
                }, ()=> {
                    that.setState({disable_credit_search: false});
                })
            }
            that.setState({disable_credit_search: false});

        }, (data) => {
            that.setState({disable_credit_search: false});
        });
    }

    render() {
        const { customer_reference, disable_credit_search } = this.state;
        return (
            <div>
                <button className="btn btn-outline-dark btn-block btn-sm" disabled={disable_credit_search}  onClick={this.creditSearchButtonClick.bind(this)}>
                    <span><img src="/images/loading.gif" height={16} /></span> 
                    Credit Safe Company Search
                </button>
            </div>
        )
    }

}

export default CreditSafeCompanySearch;