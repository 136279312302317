import React, { Component } from "react";
import Navbar from '../../components/Navbar';
import axios from 'axios';
import { Formik } from 'formik';


class ArticleEdit extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: true,
            id: props.match.params.id,
            article: {} 
        };
    }

    getData() 
    {
        return this.props.data;
    }

    updateArticle() {
        axios.put('http://evelocity.info/projects/articles-api/public/api/articles/' + this.state.id, this.state.article)
        .then(response => {
            alert('update successful');
            this.setState({ articles: response.data.data })
        })
        .catch(error => {
            alert("Error reading articles");
        });
    }

    componentDidMount() { 
        axios.get('http://evelocity.info/projects/articles-api/public/api/articles/' + this.state.id)
        .then(response => {
            this.setState({ article: response.data.data })
        })
        .catch(error => {
            alert("Error reading articles");
        });
      }

      render() {
        const { article } = this.state;
        return (
            <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Article Edit</h2>

                        <Formik
                            initialValues={{ title: "t", short: "s" }}
                            onSubmit={(e, initialValues, { setSubmitting }) => {
                                e.preventDefault();
                                this.updateArticle();
                                //alert('submit');
                                //setTimeout(() => {
                                //alert(JSON.stringify(values, null, 2));
                                //setSubmitting(false);
                                //}, 400);
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>

                                    <input type="text" id="title" name="title" />
                                    <input type="text" id="short" name="short" value={this.short} />
                                    <textarea name="content" value={article.content}></textarea>

                                    <button type="submit" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </form>
                            )}
                            </Formik>


                    </div>
                </div>
            </div>
        </div>
        );
                                
}
}

export default ArticleEdit;