import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency } from '../Utils';
import { config } from '../Configurator'
import Documents from './DocumentsCause'

class CaseLiveView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entry: {},
            customer_reference: null,
            applicant_id: this.props.id,
            name: null,
            forms: config().forms
        }
    }

    componentDidMount() {
        
        // get case details
        axios.post(config().base_url + 'GetCase', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "CaseReference": this.props.id
        })
        .then(response => {
            
            // console.log(response);
            
            console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);

            this.setState({
                entry: response.data.d.CaseApplicant, 
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                customer_reference: response.data.d.CaseApplicant.CustomerReference,
                name: (response.data.d.CaseApplicant.MainApplicant.Firstname ?? "") + ' ' + (response.data.d.CaseApplicant.MainApplicant.Surname ?? "")});
        })
        .catch(error => {
            console.log(error);
        });


    }
    render() {
        const { entry, customer_reference, name, applicant_id } = this.state;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h2>Summary</h2>
                            </div>
                            {/* <div className="col-3 text-right">
                                <Link className="float-right btn btn-light btn-sm" to='/cases'>&larr; Back</Link>
                            </div> */}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">Customer Ref:<br /><strong>{ entry.CustomerReference }</strong></div>
                                        <div className="mb-3">Customer Name:<br /><strong>{ name }</strong></div>
                                        <div className="mb-3">Customer Telephone:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.Telephone :"" }</strong></div>
                                        <div className="mb-3">Customer Mobile:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.Mobile :"" }</strong></div>
                                        <div className="mb-3">Customer Email:<br /><strong>{ entry.MainApplicant ? entry.MainApplicant.EmailAddress :"" }</strong></div>
                                        {/* <div className="mb-3">Customer Address:<br /><strong>{ entry.MainApplicant.CurrentAddress ? entry.MainApplicant.CurrentAddress ?? entry.MainApplicant.CurrentAddress.Address1 :"" }</strong></div> */}
                                        {/* <div className="mb-3">Customer Postcode:<br /><strong>{ entry.MainApplicant.CurrentAddress ? entry.MainApplicant.CurrentAddress ?? entry.MainApplicant.CurrentAddress.PostCode :"" }</strong></div> */}
                                        {/* <div className="mb-3">Customer Owner:<br /><strong>{ entry.CustomerOwner }</strong></div> */}
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="mb-3">Case Ref:<br /><strong>{ entry.ApplicantID }</strong></div>
                                        {/* <div className="mb-3">Owner:<br /><strong>{ entry.CaseOwner }</strong></div> */}
                                        <div className="mb-3">BDM:<br /><strong>{ entry.CaseUnderWriter }</strong></div>
                                        <div className="mb-3">RM:<br /><strong>{ entry.CaseProcessor }</strong></div>
                                        <div className="mb-3">Exec:<br /><strong>{ entry.CaseAdditionalUser1 }</strong></div>
                                        <div className="mb-3">Case Matter Type:<br /><strong>{ entry.MatterType }</strong></div>
                                        {/* <div className="mb-3">Term:<br /><strong>12 Months</strong></div> */}
                                        <div className="mb-3">Source:<br /><strong>{ entry.SourceName }</strong></div>
                                        <div className="mb-3">Broker Name:<br /><strong>{ entry.SourceAffiliateName }</strong></div>
                                        <div className="mb-3">Provider:<br /><strong>{ entry.Provider }</strong></div>
                                        <div className="mb-3">Credit Type:<br /><strong>{ entry.CreditType }</strong></div>
                                    </div>
                                    <div className="col-md-1"> </div>
                                    <div className="col-md-3">
                                    <div className="mb-3">Case Status:<br /><strong>{ entry.CaseStatus }</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <br />
                                <div className="row">
                                    <div className="col-md-4">Total Loan Amount<br /><h2>{ currency(entry.NetClaim) }</h2></div>
                                    {/* <div className="col-md-4">Total Paid Amount<br /><h2>{ currency(entry.TotalDebit) }</h2></div> */}
                                    <div className="col-md-4">Current Balance<br /><h2>{ currency(entry.TotalBalance) }</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h2 className="mb-3">Documents</h2>
                        <Documents context='live' customer_reference={this.state.customer_reference} applicant_id={this.state.applicant_id}></Documents>
                        {/* <Documents customer_reference={this.state.customer_reference} applicant_id={this.state.applicant_id}></Documents> */}
                        {/* <Documents context='prospects' applicant_id={applicant_id}></Documents> */}
                    </div>
                </div>
                </div>
        )
    }

}

export default CaseLiveView;