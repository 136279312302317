import React from 'react';
import Navbar from '../components/Navbar';
import { Link } from "react-router-dom";
import CasesRecent from '../components/CasesRecent';
import CasesRecentProspect from '../components/CasesRecentProspect';
import KPI from '../components/KPI';
import CustomersRecent from '../components/CustomersRecent';
import CoursesRecent from '../components/CoursesRecent';
import DelegatesRecent from '../components/DelegatesRecent';
import ArticlesRecent from '../components/ArticlesRecent';
import { config } from '../Configurator'

export default function Home() {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">


                <div className='row'>
                    <div className='col-md-8'>
                        <h1>Welcome back, { config().personName.split(" ")[0] }</h1>
                    </div>
                </div>


                {/* <div className='row'>
                    <div className='col-md-12'>
                        <KPI></KPI>                        
                    </div>
                </div> */}

                <br /><br />

                <div className="row">
                    <div className="col-md-8">

                        {/* Reward Finance ONLY This needs to be commented out for any other setups */}
                        <div className="row">
                            <div className="col-md-9"><h2>My Prospects</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/29">Add Lead</Link></div>
                            {/* <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/17">Add Prospect</Link></div> */}
                        </div>
                        <CasesRecentProspect status="Prospect" />
{/* 
                        <div className="row">
                            <div className="col-md-9"><h2>My Live Cases</h2></div>
                        </div>
                        
                        <CasesRecent status="Live" /> */}


                        {/* Enable below for Broker Lender Master Home Screen Peregrine */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>My Companies</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/24">Add Customer</Link></div>
                        </div>
                        <CustomersRecent status="Customer" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-6"><h2>My Cases</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/2">Quick Quote</Link></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div>
                        </div>
                        <CasesRecent status="Quotes" /> */}

                        {/* Enable below for Broker Lender Master Home Screen Lendology */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>My Enquiries</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/12">Add Enquiry</Link></div>
                        </div>
                        <CustomersRecent status="Prospect"/>
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>My Cases</h2></div> */}
                            {/* <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/16">Add Case</Link></div> */}
                        {/* </div>
                        <CasesRecent status="Application" /> */}


                        {/* Enable below for Training Master Home Screen */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>My Courses</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/24">Book a Course</Link></div>
                        </div>
                        <CoursesRecent status="Confirmed Course" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>My Delegates</h2></div>
                        </div>
                        <DelegatesRecent status="Active" /> */}

                        {/* <h2>Latest Tasks</h2>
                        <TasksRecent /> */}
                    </div>
                    
                        {/* Enable below for Master Home Screen */}

                        {/* <div className="col-md-4">
                        <ArticlesRecent />
                        </div> */}

                </div>
            </div>


        </div>
    );
}