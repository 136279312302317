import React from 'react';
 
function Loading() {
 
  return (
      <div>
          <div className="loader"></div>
      </div>
  );
}
 
export default Loading; 