import React, { Component } from 'react';
import { config } from '../Configurator'
import { creditSearch, getPdf } from '../Utils';

class creditSafe extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customer_reference: null,
            disable_credit_search: false,
            forms: config().forms,
        }
    }

    componentDidMount() {
        this.setState({ 'customer_reference': this.props.customer_reference});
    }

    creditSearchButtonClick() {

        let that = this;
        that.setState({disable_credit_search: true});
        creditSearch(this.state.customer_reference, 'pdf').then((pdf_path) => {
            if(pdf_path) {
                getPdf(pdf_path, this.state.customer_reference).then((data) => {
                    that.setState({disable_credit_search: false});
                }, (data) => {
                    that.setState({disable_credit_search: false});
                });
                
            } else {
                alert("PDF not found");
            }
        }, () => {
            // error
            that.setState({disable_credit_search: false});
        });
    }

    render() {
        const { customer_reference, disable_credit_search } = this.state;
        return (
            <div>
                <button className="btn btn-outline-dark btn-block btn-sm my-2" disabled={disable_credit_search} onClick={this.creditSearchButtonClick.bind(this)}>
                    <span><img src="/images/loading.gif" height={16} /></span> Company Credit Search
                </button>
            </div>
        )
    }

}

export default creditSafe;