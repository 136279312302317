import React from 'react';
import CustomerView from '../components/CustomerView';
import Navbar from '../components/Navbar';

export default function Customer(props) {

    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <CustomerView id={props.match.params.id}  />
                    </div>
                </div>

            </div>

        </div>
    );
}