import React from 'react';
import ProspectsTable from '../components/ProspectsTable';
import Navbar from '../components/Navbar';

export default function Customers() {
    return (
        <div>
            <Navbar />
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <ProspectsTable />
                    </div>
                </div>
            </div>
        </div>
    );
}