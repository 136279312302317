import axios from 'axios';
import { config } from '../Configurator'
import { buildCaseAssessorPayload, convertDateForApi, convertToDouble } from '../Utils'
import alertify from 'alertifyjs';

const endpoint = "UpdateCase";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeUpdateCaseDataReviewFinalVar = (att, applicant_id, customer_reference) => {

    if(applicant_id===null) {
        alertify.confirm("Error", "Applicant ID can't be null", null, null);
        return false;
    }

    att.caseAssessor26 = att.caseAssessor59
    att.caseAssessor27 = att.caseAssessor64
    att.caseAssessor15 = att.caseAssessor66
    att.caseAssessor74 = att.caseAssessor62
    // New Risk Data
    att.caseAssessor31 = att.caseAssessor68
    att.caseAssessor32 = att.caseAssessor69
    att.caseAssessor33 = att.caseAssessor70
    att.caseAssessor34 = att.caseAssessor71
    att.caseAssessor35 = att.caseAssessor72

    // #1 Update case data 
    axios.post(config().base_url + endpoint, updateCasePayload(att, applicant_id, customer_reference)).then(function(response){
        let value = '';
        if(response.data.d.UpdateResult.SuccessCode==0) {

            // #2 if update goes well, get case assessor 

            value = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, buildCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            if(att.form_id=='13') { // delay for sound to play 
                                setTimeout(function(){ window.location.href = "/case/" + applicant_id;  }, 2800);
                            } else {
                                window.location.href = "/case/" + applicant_id;
                            }
                        } else {
                            alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);
                        }
                    }).catch(function (error){
                        alertify.confirm('Error', error.message, null, null);
                    });

                } else {
                    alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);

                }
            }).catch(function (error){
                alertify.confirm('Error', error.message, null, null);
            });

            // window.location.href = "/thanks/" + response.data.d.Value;
        } else {
            alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);
        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "MainApplicant": {
                "Title": '',
                "Firstname": '',
                "Surname": '',
                "DateOfBirth": convertDateForApi(""),
                "Telephone": '',
                "WorkTelephone": '',
                "Mobile": '',
                "EmailAddress": '',
                "BankDetail": [{
                    "BankName": att.bank_name,
                    "AccountName": att.account_name,
                    "AccountNumber": att.account_number,
                    "SortCode": att.sort_code
                }],
                "CurrentAddress": {
                    "HouseName": '',
                    "HouseNumber": '',
                    "Address1": '',
                    "Address2": '',
                    "Address3": '',
                    "Address4": '',
                    "PostCode": ''
                },
                "PreviousAddresses": {},
                "App2ID": 0
            },
            "AdditionalApplicants": [
                {
                    "Title": "",
                    "Firstname": att.mc1_Firstname__1,
                    "Surname": att.mc1_Surname__1,
                    "DateOfBirth": att.mc1_DateOfBirth__1,
                    "Telephone": att.mc1_telephone__1,
                    "WorkTelephone": att.mc1_telephone__1,
                    "Mobile": att.mc1_mobile__1,
                    "EmailAddress": att.mc1_email__1,
                    "CurrentAddress": {
                        "HouseName": "",
                        "HouseNumber": "",
                        "Address1": att.mc1_address1__1,
                        "Address2": att.mc1_address2__1,
                        "Address3": att.mc1_address3__1,
                        "Address4": att.mc1_address4__1,
                        "PostCode": att.mc1_postcode__1
                    },
                },
                {
                    "Title": "",
                    "Firstname": att.mc1_Firstname__2,
                    "Surname": att.mc1_Surname__2,
                    "DateOfBirth": att.mc1_DateOfBirth__2,
                    "Telephone": att.mc1_telephone__2,
                    "WorkTelephone": att.mc1_telephone__2,
                    "Mobile": att.mc1_mobile__2,
                    "EmailAddress": att.mc1_email__2,
                    "CurrentAddress": {
                        "HouseName": "",
                        "HouseNumber": "",
                        "Address1": att.mc1_address1__2,
                        "Address2": att.mc1_address2__2,
                        "Address3": att.mc1_address3__2,
                        "Address4": att.mc1_address4__2,
                        "PostCode": att.mc1_postcode__2
                    },
                },
                {
                    "Title": "",
                    "Firstname": att.mc1_Firstname__3,
                    "Surname": att.mc1_Surname__3,
                    "DateOfBirth": att.mc1_DateOfBirth__3,
                    "Telephone": att.mc1_telephone__3,
                    "WorkTelephone": att.mc1_telephone__3,
                    "Mobile": att.mc1_mobile__3,
                    "EmailAddress": att.mc1_email__3,
                    "CurrentAddress": {
                        "HouseName": "",
                        "HouseNumber": "",
                        "Address1": att.mc1_address1__3,
                        "Address2": att.mc1_address2__3,
                        "Address3": att.mc1_address3__3,
                        "Address4": att.mc1_address4__3,
                        "PostCode": att.mc1_postcode__3
                    },
                },
                {
                    "Title": "",
                    "Firstname": att.mc1_Firstname__4,
                    "Surname": att.mc1_Surname__4,
                    "DateOfBirth": att.mc1_DateOfBirth__4,
                    "Telephone": att.mc1_telephone__4,
                    "WorkTelephone": att.mc1_telephone__4,
                    "Mobile": att.mc1_mobile__4,
                    "EmailAddress": att.mc1_email__4,
                    "CurrentAddress": {
                        "HouseName": "",
                        "HouseNumber": "",
                        "Address1": att.mc1_address1__4,
                        "Address2": att.mc1_address2__4,
                        "Address3": att.mc1_address3__4,
                        "Address4": att.mc1_address4__4,
                        "PostCode": att.mc1_postcode__4
                    },
                }
            ],
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "InterestMonthly": att.caseAssessor61,
            "NetClaim": convertToDouble(att.caseAssessor58),
            "CustomerInitialFee": '',
            "CustomerCompletionFee": convertToDouble(att.caseAssessor63),
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi("") ,
            "Source": att.Source,
            "SourceRef": att.SourceRef,
            "SourceAffiliate": '',
            "SourceInitialFee": convertToDouble(att.SourceInitialFee),
            "CaseAssignmentBranch": att.CaseAssignmentBranch,
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',

            // 
            "CaseOwner": "",
            "CustomerOwner": "",
            "CaseUnderWriter": att.UnderWriter,
            "CaseProcessor": att.Processor,
            "CaseAdditionalUser1": att.AdditionalUser1,
            "CaseAdditionalUser2": "",

            "ApplicationProgressDates" : {
                "PaymentDate": att.caseAssessor65
                },

            "DynamicFieldDetail": [
            {
                "FieldNo": 3,
                "FieldValue": att.caseAssessor73
            },
            {
                "FieldNo": 5,
                "FieldValue": att.caseAssessor60
            },
            {
                "FieldNo": 6,
                "FieldValue": att.caseAssessor75
            },
            {
                "FieldNo": 12,
                "FieldValue": att.caseAssessor67
            }
            ],

            
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 
    for(let i=0; i<100; i++) {
        if(typeof att['SecuirtyCode__' + i] !=='undefined') {
            console.log(att['SecuirtyCode__' + i]);

            ret.CaseApp.SecurityAddress.push(
                {
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": att['SecurityHouseName__' + i],
                    "HouseNumber": att['SecurityHouseNo__' + i],
                    "Address1": att['SecurityAddress1__' + i],
                    "Address2": att['SecurityAddress2__' + i],
                    "Address3": att['SecurityAddress3__' + i],
                    "Address4": att['SecurityAddress4__' + i],
                    "Postcode": att['SecurityPostcode__' + i],
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['SecurityType__' + i],
                    "SecuritySubType": att['SecuritySubType__' + i],
                    "SecuirtyDetails": att['secuirtyDetails__' + i],
                    "SecuirtyCharge": att['SecurityCharge__' + i],
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(null),
                    "SecuirtyEquityValue": att['SecuirtyEquityValue__' + i],
                    "SecuirtyLendingValue": att['SecuirtyLendingValue__' + i],
                    "SecuirtyAgreedCap": att['SecuirtyAgreedCap__' + i],
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": convertDateForApi(''),
                    "SecuirtyMortgageValue": 0,
                    "SecurityStatus": att['SecurityStatus__' + i],
                    "SecuirtyMarketValue": Number(att['security_multiplier__' + i]),
                    "SecurityTitleNumber": att['SecurityTitle__' + i],
                    "SecurityInsuranceExpiryDate": att['SecurityInsuranceExpiryDate__' + i],
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": att['SecuirtyGivenBy__' + i]
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": att['SecuirtyValuationType__' + i]
                        },
                        {
                            "CustomFieldNo": "3",
                            "CustomFieldValue": att['SecuirtyValuationDate__' + i]
                        },
                        {
                            "CustomFieldNo": "4",
                            "CustomFieldValue": att['SecuirtyValuer__' + i]
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

export default {
    executeUpdateCaseDataReviewFinalVar
};
