import axios from 'axios';
import { config } from '../Configurator'
import { convertDateForApi, convertToDouble } from '../Utils'
import alertify from 'alertifyjs';

const endpoint = "CreateNewProspectV2";

export const executeCreateNewProspectV2Lend = (att) => {
    
    let payload = {
        "userInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "customer": {
            "Title": att.Title,
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "CompanyName": att.CompanyName,
            "Telephone": att.Telephone,
            "Mobile": att.Mobile,
            "Email": att.Email,
            "DateOfBirth": convertDateForApi(""),
            "CustomerStatus": "Prospect",
            "B2BCustomer": "false",
            "Source": att.Source,
            "Notes": att.Notes,
            "AltSalutation": att.AltSalutation,
            "InvoiceTermName":"",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.address1,
                "Address2": att.address2,
                "Address3": att.address3,
                "Address4": att.address4,
                "PostCode": att.postcode
            },
            "CustomFields": [
                {
                    "CustomFieldNo": 1,
                    "CustomFieldValue": att.CustomField1
                },
                {
                    "CustomFieldNo": 2,
                    "CustomFieldValue": att.CustomField2
                },
                {
                    "CustomFieldNo": 3,
                    "CustomFieldValue": att.CustomField3
                },
                {
                    "CustomFieldNo": 4,
                    "CustomFieldValue": att.CustomField4
                },
                {
                    "CustomFieldNo": 5,
                    "CustomFieldValue": att.CustomField5
                },
                {
                    "CustomFieldNo": 6,
                    "CustomFieldValue": att.CustomField6
                },
                {
                    "CustomFieldNo": 7,
                    "CustomFieldValue": att.CustomField7
                },
                {
                    "CustomFieldNo": 8,
                    "CustomFieldValue": att.CustomField8
                },
                {
                    "CustomFieldNo": 9,
                    "CustomFieldValue": att.CustomField9
                },
                {
                    "CustomFieldNo": 10,
                    "CustomFieldValue": att.CustomField10
                },
                {
                    "CustomFieldNo": 11,
                    "CustomFieldValue": att.CustomField11
                },
                {
                    "CustomFieldNo": 12,
                    "CustomFieldValue": att.CustomField12
                }
            ]
        },
        "ClientPassword": ""
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }
    }).catch(function (error){
        alertify.confirm('Error', error.message, null, null);
    });

}

export default {
    executeCreateNewProspectV2Lend
};
