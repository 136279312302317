import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, orderResult, getPaginate } from '../Utils';
import Loading from './Loading';
import Pagination from '../components/Pagination';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class SourceTable extends Component {

    constructor(props) {
        super(props)
        this.userChange = this.userChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.state = {
            loading: true,
            user: null,
            pagination: {
                rpp: 25,
                current_page: 1,
                total_results: 0,
                nb_pages: 0
            },
            customers: []
        } 
    }

    userChange(event) {
        let {name: fieldName, value} = event.target;
        this.load(value);
    }

    loadCustomers() {
        this.load(null);
    }

    onPageChange(v) {
        let pagination = this.state.pagination;
        pagination.current_page = v;
        this.setState({pagination: pagination},  () => {
            this.load();
        })
    }

    load(user) {
        if(user===null) user = localStorage.getItem('bo_email');
        this.setState({'loading': true});
        axios.post(config().base_url + 'GetSource', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
                "pRequest": {
                  "Status": "Active",
                  "SortType": "",
                  "SortBy": "",
                  "SearchType": "Name",
                  "SearchText": "",
                  "RecordStartNo": "1",
                  "RecordEndNo": "10000"
                }
          })
        .then(response => {

            let customers =  orderResult(response.data.d.Records, "CustomerReference", "desc");
            // let customers = response.data.d.Records;

            let pagination = this.state.pagination;
            pagination.total_results = customers.length;
            pagination.nb_pages = Math.ceil(pagination.total_results / pagination.rpp);
            let data = getPaginate(customers, pagination.rpp, pagination.current_page);
            this.setState({customers: data, pagination: pagination, loading: false});
        })
        .catch(error => {
            this.setState({loading: false});
            console.log(error);
        });
    }

    componentDidMount() {
        this.loadCustomers();
    }

    render() {
        const { customers, loading, pagination } = this.state;
        return (
            <div>


                {/* <div className="row d-none">
                    <div className="col-md-4">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                            <option value="select">Select</option>
                            <option value="Apples">Apples</option>
                            <option value="Mangoes">Mangoes</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        { this.state.user }
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-9"><h4 className="font-weight-bold">Sources</h4></div>
                    <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/35">Add Source</Link></div>
                    {/* <div className="col-md-4">
                        { this.state.user }
                    </div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/2">Quick Quote</Link></div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div> */}
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Company</th>
                                        <th>Telephone</th>
                                        <th>Email</th>
                                        <th>Source Status</th>
                                        <th>Records Assigned</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        customers.length ?
                                        customers.map(entry => {
                                            return <tr key={entry.CustomerReference}>
                                                <td>{ entry.SourceCode }</td>
                                                <td>{ entry.SourceName }</td>
                                                <td>{ entry.Telephone }</td>
                                                <td>{ entry.Email }</td>
                                                <td>{ entry.SourceStatus }</td>
                                                <td>{ entry.SourceCaseDetail.TotalCases }</td>
                                                {/* <td>
                                                    <Link to={ '/customer/' + entry.CustomerReference } className="btn-more"></Link>
                                                </td> */}
                                            </tr> 
                                        }) : 
                                        null
                                    }
                                </tbody>
                            </table>
                            <Pagination className="pagination-bar" currentPage={pagination.current_page} totalCount={pagination.total_results} pageSize={pagination.rpp} onPageChange = {this.onPageChange} />
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default SourceTable;