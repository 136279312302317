import React from 'react';
import SourceTable from '../components/SourceTable';
import Navbar from '../components/Navbar';

export default function Customers() {
    return (
        <div>
            <Navbar />
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <SourceTable rpp="4" />
                    </div>
                </div>
            </div>
        </div>
    );
}